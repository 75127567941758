import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAKPOCkHsmSDdrO5fX-RwoCy6xoAh6MIi4",
  authDomain: "tecodinx.firebaseapp.com",
  projectId: "tecodinx",
  storageBucket: "tecodinx.appspot.com",
  messagingSenderId: "319462834100",
  appId: "1:319462834100:web:5cfbf2e97012ff81c9bce1",
  measurementId: "G-RC5Z84DVH1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
logEvent(analytics, "Site Opened");

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
