import React from "react";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Container,
  createTheme,
  Grid,
  Paper,
  ThemeProvider,
} from "@mui/material";

const theme = createTheme({
  palette: {
    background: {
      default: "#424242",
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        component={Container}
        maxWidth="md"
        sx={{ height: "100vh" }}
      >
        <Grid
          container
          item
          direction="row"
          alignItems="center"
          justifyContent="center"
          component={Paper}
          elevation={4}
          padding={2}
          alignContent="center"
          sx={{ flexGrow: 1, maxHeight: 600 }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h1"
              sx={{ textAlign: "center", fontSize: "8vw" }}
            >
              TecodinX
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              sx={{ textAlign: "center", fontSize: "5vw" }}
            >
              Comming soon...
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default App;
